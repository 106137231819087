







































































import { Component, Vue } from 'vue-property-decorator';
import CartCoreModel from '@/modules/carts/models/CartCoreModel';

import CartLib from '@/app/lib/cart/Cart';
import CartItemsTable from '@/app/modules/cart/components/partials/tables/CartItemsTable.vue';
import Icon from '@/shared/resources/components/Icon.vue';
import Divider from '@/shared/resources/components/Divider.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import Sticky from '@/shared/resources/components/Sticky.vue';
import CartItemCoreModel from '@/modules/carts/models/CartItemCoreModel';
import ButtonsRow from '@/shared/resources/components/buttons/ButtonsRow.vue';
import Popup from '@/shared/lib/support/popups/Popup';
import GridSpacer from '@/shared/resources/components/grid/GridSpacer.vue';
import IconMessage from '@/shared/resources/components/IconMessage.vue';
import { redirect } from '@/shared/lib/support/router/RouterUtils';
import Order from '@/app/lib/order/Order';
import Tooltip from '@/shared/resources/components/Tooltip.vue';
import Alert from '@/shared/resources/components/Alert.vue';
import { minOrderString } from '@/app/modules/online-orders/config/onlineOrders';
import Loader from '@/shared/resources/components/Loader.vue';

@Component({
  components: {
    Loader,
    Alert,
    Tooltip,
    IconMessage,
    GridSpacer,
    ButtonsRow,
    Sticky,
    GridRow,
    Button,
    Divider,
    Icon,
    CartItemsTable,
  },
})
export default class Cart extends Vue {
  /**
   * Getters
   */
  private get cart(): CartCoreModel {
    return CartLib.model!;
  }

  private get cartItems(): CartItemCoreModel[] {
    return CartLib.items();
  }

  private get minAmountAlertMessage(): string {
    return `Minimalna wartość zamówienia to ${minOrderString()}`;
  }

  private get disableCheckoutButton(): boolean {
    return !Order.conditionsMet();
  }

  /**
   * Display getters
   */
  private get displayCart(): boolean {
    return CartLib.isInitiated() && !CartLib.isEmpty();
  }

  private get displayMinAmountAlert(): boolean {
    return !Order.conditionsMet();
  }

  private get displayNoItemsInfo(): boolean {
    return CartLib.isInitiated() && CartLib.isEmpty();
  }

  private get displayLoader(): boolean {
    return !CartLib.isInitiated();
  }

  /**
   * Methods
   */
  private removeAllCartItems() {
    CartLib.removeAllItems();
  }

  private goToProducts() {
    redirect({ name: 'products' });
  }

  /**
   * Handlers
   */
  private onRemoveAllButtonClick() {
    Popup.confirm('Na pewno chcesz usunąć wszystkie pozycje z koszyka?').then(() => {
      this.removeAllCartItems();
    }).catch(() => {
      // do nothing
    });
  }

  private onGoToProductsButtonClick() {
    this.goToProducts();
  }
}
